.lab-confirm {
  margin: 0 20px;
}

@media screen and (min-width: 1200px) {
  .lab-confirm {
    margin: 0 auto;
    width: 800px;
    max-width: 800px;
  }
}

.mt-4{
    margin-top: 1rem;
}

.responsive {
  width: 100%;
  height: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
  padding: 0 1.5rem;
}
