.no-bg {
}

body {
  min-width: 978px;
  padding: 0;
  background: white;
  color: #2a2b2a;
  font-family: OpenSansRegular, sans-serif;
  font-size: 13px;
  margin: 0;
}

ul li {
  list-style-type: none;
}

.aui .dockbar .navbar-inner .container {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.aui #heading > h1 {
  margin: 0px;
}

body.blue #heading {
  margin: 0 auto;
}
.aui #heading {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.aui .dockbar.navbar-static-top .collapse {
  position: static;
}

.aui h1,
.aui h2,
.aui h3,
.aui h4,
.aui h5,
.aui h6 {
  margin: 10px 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 20px;
  color: inherit;
  text-rendering: optimizelegibility;
}

.blue {
  margin-bottom: 0;
  background-image: url(/src/images/kek_bg.png);
  background-position: calc(50% - 436px)-1px;
  background-repeat: no-repeat;
}

body.blue .dockbar .navbar-inner {
  height: 89px;
  background-color: transparent;
  background-image: url(./images/kek_logo.png);
  background-position: calc(50% - 430px) center;
  box-shadow: none !important;
}

.aui #skip-to-content {
  display: none;
}

.portlet-borderless-container {
  min-height: 2.5em;
  position: relative;
}

.aui .dockbar .navbar-inner {
  background-image: url(./images/kek_logo.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.aui #_145_dockbarbodyContent {
  display: flex;
  width: 100%;
}

.aui .navbar-inner:before,
.aui .navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}

.aui #_145_dockbar > .navbar-inner > .container {
  display: flex;
}

.aui .container:before,
.aui .container:after {
  display: table;
  content: "";
  line-height: 0;
}

.aui #wrapper {
  padding: 0;
}

#wrapper {
  padding: 2em 5em 0;
  position: relative;
}

.aui .container-fluid {
  padding-right: 20px;
  padding-left: 20px;
  *zoom: 1;
}

.aui .container-fluid:before,
.aui .container-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}

.aui article,
.aui aside,
.aui details,
.aui figcaption,
.aui figure,
.aui footer,
.aui header,
.aui hgroup,
.aui nav,
.aui section {
  display: block;
}

body.blue #header {
  background: #0091ca;
}

.aui #header #header-controls {
  position: relative;
  max-width: 978px;
  margin: 0 auto;
}

.aui #header #header-controls {
  position: relative;
  max-width: 978px;
  margin: 0 auto;
}

.aui #header #header-controls {
  max-width: 978px;
  margin: 0 auto;
}

.aui .row-fluid [class*="span"]:first-child {
  margin-left: 0;
}

.aui .row-fluid .span11 {
  width: 100%;
}

.aui .row-fluid .span11 {
  width: 91.48936%;
  *width: 91.43617%;
}

.aui .row-fluid [class*="span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.12766%;
  *margin-left: 2.07447%;
}

.aui .span11 {
  width: 860px;
}

.aui [class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

.aui body {
  margin-bottom: 25px;
  min-width: 978px;
  padding: 0;
  background: white;
  color: #2a2b2a;
  font-family: OpenSansRegular, sans-serif;
  font-size: 13px;
}

.aui body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background-color: white;
}

.aui article,
.aui aside,
.aui details,
.aui figcaption,
.aui figure,
.aui footer,
.aui header,
.aui hgroup,
.aui nav,
.aui section {
  display: block;
}

.aui #header #header-controls #navigation-container ul.ehealth-nav {
  position: static;
}

.aui #header #header-controls #navigation-container ul {
  margin: 0;
  padding-bottom: 10px;
  font-size: 0;
  list-style: none;
}

.aui #header #header-controls #navigation-container ul {
  float: left;
  margin: 0;
  font-size: 0;
  list-style: none;
}

.aui ul,
.aui ol {
  padding: 0;
  margin: 0 0 10px 25px;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.modify-pages li {
  position: relative;
}

.aui li {
  line-height: 20px;
}

.aui #header #header-controls #navigation-container ul {
  margin: 0;
  padding-bottom: 10px;
  font-size: 0;
  list-style: none;
}

.aui #header #header-controls #navigation-container ul {
  float: left;
  margin: 0;
  font-size: 0;
  list-style: none;
}

body.blue #header #header-controls #navigation-container ul li a {
  border-right: 1px solid #0091ca;
  color: white;
  text-transform: none;
}

.aui #header #header-controls #navigation-container ul li a {
  display: block;
  padding: 0 18px;
  border-right: 1px solid #259354;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 40px;
  white-space: nowrap;
}

body.blue a {
  color: #0091ca;
}

.aui a {
  color: #00aaff;
  text-decoration: none;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.aui li {
  line-height: 20px;
}

.aui #header #header-controls #navigation-container ul {
  margin: 0;
  padding-bottom: 10px;
  font-size: 0;
  list-style: none;
}

.aui #header #header-controls #navigation-container ul {
  float: left;
  margin: 0;
  font-size: 0;
  list-style: none;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.aui .dropup,
.aui .dropdown {
  position: relative;
}

.aui li {
  line-height: 20px;
}

.aui #header #header-controls #navigation-container ul {
  margin: 0;
  padding-bottom: 10px;
  font-size: 0;
  list-style: none;
}

.aui #header #header-controls #navigation-container ul {
  float: left;
  margin: 0;
  font-size: 0;
  list-style: none;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.aui #header #header-controls #navigation-container ul li {
  display: inline-block;
  font-size: 13px;
}

.modify-pages li {
  position: relative;
}

.aui li {
  line-height: 20px;
}

.aui #header #header-controls .header-controls-right {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  width: auto !important;
}

body.blue #main-content {
  margin: 0 auto;
  padding: 20px 20px 74px;
  min-height: calc(100vh - 364px);
  background-color: white;
}

.aui #main-content {
  margin-top: 20px;
}

.aui #main-content {
  display: block;
  max-width: 978px;
  margin: 44px auto;
}

.aui .table {
  width: 100%;
  margin-bottom: 20px;
}

.aui table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.aui .table th,
.aui .table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.aui #main-footer .portlet-body {
  width: 100%;
  max-width: 978px;
  margin: 0 auto;
}

body.blue #contacts {
  margin-top: -40px;
  margin-bottom: 16px;
  color: white;
}

.portlet-borderless-container {
  min-height: 2.5em;
  position: relative;
}

body.blue #contacts .span4 {
  height: 60px;
  background-color: #0091ca;
  background-position: left center;
  background-repeat: no-repeat;
}

body.blue #footer-address {
  padding-top: 12px;
  padding-left: 60px;
  background-image: url(./images/footer-address.png);
  font-size: 15px;
}

body.blue #footer-phone {
  font-size: 24px;
  line-height: 60px;
  background-image: url(./images/footer-phone.png);
  text-align: center;
}

body.blue #footer-email {
  font-size: 18px;
  line-height: 60px;
  background-image: url(./images/footer-email.png);
  text-align: center;
}

body.blue #main-footer ul {
  margin: 0;
  padding-top: 0;
  border-bottom: 0;
}

.aui #main-footer ul {
  max-width: 670px;
  margin: 0 0 15px 0;
  padding-top: 22px;
  padding-bottom: 3px;
  border-bottom: 1px solid #238b4c;
  list-style: none;
  height: 20px !important;
}

body.blue #main-footer ul li a,
body.blue #main-footer ul li a:hover,
body.blue #main-footer ul li a:focus {
  color: #005c99;
  font-size: 14px;
  font-weight: normal;
  line-height: 80px;
}

.aui #main-footer ul li a,
.aui #main-footer ul li a:hover,
.aui #main-footer ul li a:focus {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.aui .eeszt-portlet-layout-h1 {
  font-family: Bold;
  font-size: 16px;
  color: #3ebc68;
  margin: 0px;
}

body.blue .eeszt-portlet-layout-h1 {
  color: #0091ca;
}

.eeszt-portlet-layout-h1 {
  display: flex;
  background: #0091ca;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: white !important;
  font-size: 20px !important;
}

body.blue #szechenyi {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px;
  height: 242px;
  background-image: url(./images/szechenyi_2020.png);
  background-repeat: no-repeat;
  transition: all 0.4s ease;
}

body.blue .portal-header-title {
  margin-left: 80px !important;
  color: #005c99;
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 84px !important;
}

.aui .collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.aui .nav-collapse.collapse {
  height: auto;
  overflow: visible;
}

.aui #main-footer .portlet-body {
  width: 100%;
  max-width: 978px;
  margin: 0 auto;
}

body.blue #main-footer {
  height: 120px;
  border-top: 10px solid white;
  background-color: #c3dae2;
  background-image: none;
}

body.blue #main-footer ul li a,
body.blue #main-footer ul li a:hover,
body.blue #main-footer ul li a:focus {
  color: #005c99;
  font-size: 14px;
  font-weight: normal;
  line-height: 80px;
}

.aui #main-footer ul li {
  display: inline-block;
  margin-right: 8px;
  vertical-align: bottom;
}

.aui li {
  line-height: 20px;
}

body.blue #main-footer p {
  color: #005c99;
  font-size: 14px;
  line-height: 80px;
}

.aui #main-footer p {
  max-width: 670px;
  margin: 0;
  color: #016237;
}

.aui p {
  font-size: 14px;
  color: #202020;
  line-height: 1, 5x;
}

body.blue #main-footer span.audit {
  display: inline-block;
  width: 70px;
  height: 69px;
  background-image: url(./images/audit.png);
  background-repeat: no-repeat;
  text-indent: -9999px;
  vertical-align: top;
}

body.blue #contacts a {
  color: white;
  text-decoration: none;
}

body.blue #_145_navAccountControlsNavbarCollapse {
  margin-left: 0;
  min-width: 264px;
}

.aui .dockbar .navbar-inner .container .nav-account-controls {
  float: right;
}

.aui #_145_navAccountControlsNavbarCollapse > ul > li {
  line-height: 40px !important;
}

.aui .navbar .nav > li {
  float: left;
}

.aui .navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f9f9f9;
}

.aui #_145_navAccountControlsNavbarCollapse > ul > li {
  line-height: 40px !important;
}

.aui .navbar .nav li > a {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

body.blue #_145_navAccountControlsNavbarCollapse > ul > .sign-in > a > span {
  background-color: #0091ca;
  border-color: #0091ca;
}

.aui #_145_navAccountControlsNavbarCollapse > ul > .sign-in > a > span {
  height: 30px;
  margin: 0px 3px 0px 3px;
  padding: 7px;
  font-family: Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  text-shadow: none;
  vertical-align: middle;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.5;
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  transition: all 0.5;
  outline: none;
  background-color: #3ebb68;
  border-color: #3ebb68;
  color: white;
}

.aui #help {
  float: right;
  height: 60px !important;
}

body.blue #help > a {
  color: #0091ca !important;
}

.aui #help > a {
  color: #3ebb68;
}

body.blue a {
  color: #0091ca;
}

@media (min-width: 1200px) {
  .aui .row-fluid {
    width: 100%;
    *zoom: 1;
  }

  .aui .row-fluid:before,
  .aui .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }

  .aui .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }

  .aui .row-fluid .span11 {
    width: 91.45299%;
    *width: 91.3998%;
  }

  .aui .row-fluid [class*="span"] {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.5641%;
    *margin-left: 2.51091%;
  }

  .aui .span11 {
    width: 1070px;
  }

  .aui [class*="span"] {
    float: left;
    min-height: 1px;
    margin-left: 30px;
  }

  .aui .row-fluid .span1 {
    width: 5.98291%;
    *width: 5.92971%;
  }

  .aui .row-fluid:after {
    clear: both;
  }

  .aui .row-fluid:before,
  .aui .row-fluid:after {
    display: table;
    content: "";
    line-height: 0;
  }

  .aui .row-fluid [class*="span"]:first-child {
    margin-left: 0;
  }

  .aui .row-fluid .span12 {
    width: 100%;
    *width: 99.94681%;
  }

  .aui .row-fluid:after {
    clear: both;
  }

  .aui .row-fluid .span4 {
    width: 31.62393%;
    *width: 31.57074%;
  }
}

@media (min-width: 980px) {
  .aui .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}

@media (max-width: 767px) {
  .aui .navbar-fixed-top,
  .aui .navbar-fixed-bottom,
  .aui .navbar-static-top {
    margin: 0;
  }

  .aui .navbar-fixed-top,
  .aui .navbar-fixed-bottom,
  .aui .navbar-static-top {
    margin-left: -20px;
    margin-right: -20px;
  }

  #wrapper {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .aui .container-fluid {
    padding: 0;
  }

  .aui #header {
    height: auto;
  }

  .aui .row-fluid {
    width: 100%;
  }

  .aui [class*="span"],
  .aui .uneditable-input[class*="span"],
  .aui .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .aui #header #header-controls #navigation-container {
    height: auto;
  }

  .aui #navigation {
    display: none;
  }

  .aui #header #header-controls #navigation-container ul {
    float: none;
  }

  .aui #header #header-controls #navigation-container ul li {
    display: block;
    text-align: center;
  }

  .aui #header #header-controls #navigation-container ul li {
    display: block;
    text-align: center;
  }

  .aui #header #header-controls .header-controls-right {
    text-align: center;
  }

  .aui .span12,
  .aui .row-fluid .span12 {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .aui [class*="span"],
  .aui .uneditable-input[class*="span"],
  .aui .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .aui [class*="span"],
  .aui .uneditable-input[class*="span"],
  .aui .row-fluid [class*="span"] {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .aui body {
    min-width: 0;
  }
}

@media (max-width: 979px) {
  .aui .navbar .container {
    width: auto;
    padding: 0;
  }

  .dockbar .navbar-inner .btn-navbar {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
  }

  .navbar .container .btn-navbar {
    color: #000;
    display: inline-block;
    margin-bottom: 5px;
    width: auto;
  }

  .aui .navbar .btn-navbar {
    display: block;
  }
}
